import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import Modal from "react-bootstrap/Modal";

import ComplexTable from "../../../components/ComplexTable";
import api from "src/services/axios";

import { useParams } from "react-router-dom";
import { format, parseISO } from "date-fns";

import { useForm, Controller } from "react-hook-form";
import Textarea from "../../../components/Textarea";

interface ObservationData {
  id: string;
  description: string;
  createdAt: string;
  updatedAt: string;
}

const ObservationsList: React.FC = () => {
  let { id } = useParams();
  const [openedModal, setOpenedModal] = useState<boolean>(false);

  const [observations, setObservations] = useState<ObservationData[]>([]);

  const { handleSubmit, control, reset } = useForm({
    reValidateMode: "onSubmit",
    defaultValues: {
      description: "",
    },
  });

  useEffect(() => {
    api.get(`observations?trainee_id=${id}`).then((response) => {
      setObservations(
        response.data.map((item) => {
          return {
            id: item.id,
            description: item.description,
            createdAt: format(
              parseISO(item.created_at.substring(0, 10)),
              "dd/MM/yyyy"
            ),
            updatedAt: format(
              parseISO(item.updated_at.substring(0, 10)),
              "dd/MM/yyyy"
            ),
            username: item.user.username,
          };
        })
      );
    });
  }, [id]);

  const expandRow = {
    renderer: (row) => (
      <div
        style={{
          minHeight: "100px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        {row.description}
      </div>
    ),
  };

  const handleClose = () => setOpenedModal(false);
  const handleShow = () => setOpenedModal(true);

  const onSubmit = (data) => {
    api
      .post("/observations", {
        trainee_id: id,
        description: data.description,
      })
      .then((response) => {
        const observation = {
          id: response.data.id,
          description: response.data.description,
          createdAt: format(parseISO(response.data.created_at), "dd/MM/yyyy"),
          updatedAt: format(parseISO(response.data.updated_at), "dd/MM/yyyy"),
        };
        setObservations([...observations, ...[observation]]);
        reset();
        handleClose();
      });
  };

  return (
    <Container fluid>
      <Modal show={openedModal} onHide={handleClose}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>Adicionar Observação</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Controller
              as={Textarea}
              control={control}
              name="description"
              label="Descrição"
              md="12"
              required
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleClose}>
              Cancelar
            </Button>
            <Button type="submit" variant="success">
              Salvar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Card>
        <Card.Header
          className="border-none d-flex justify-content-between align-items-center"
          as="h5"
        >
          Observações
          <Button
            onClick={handleShow}
            className="button-primary d-flex align-items-center"
          >
            <i className="bi bi-plus-lg mr-2"></i>
            Nova observação
          </Button>
        </Card.Header>
        <Card.Body>
          <ComplexTable
            data={observations}
            columns={[
              {
                dataField: "description",
                text: "Descrição",
                sort: true,
                filter: false,
                formatter: (_, row) => <p>{row.description?.substr(0, 50)}</p>,
              },
              {
                dataField: "createdAt",
                text: "Cadastrado em",
                sort: true,
                filter: false,
              },
              {
                dataField: "username",
                text: "Cadastrado por",
                sort: true,
                filter: false,
              },
            ]}
            expandRow={expandRow}
          />
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ObservationsList;
