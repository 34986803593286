import { ResponseFetchOneSolicitation } from "src/types/SolicitationsTypes";
import api from "./axios";


class SolicitationService {
  private endpoint = "/solicitations";

  async fetchOneSolicitation(
    id: number
  ) {
    const { data } = await api.get(this.endpoint + `/${id}`);
    return data as ResponseFetchOneSolicitation;
  }
}

const solicitationService =
  new SolicitationService();

export default solicitationService;
