import api from "./axios";

class EducationalInstitutionService {
  private endpoint = "/educational_institutions";

  async fetchAll() {
    const { data } = await api.get(this.endpoint);
    return data;
  }
}

const educationalInstitutionService = new EducationalInstitutionService();

export default educationalInstitutionService;