import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
// Importações do bootstrap
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";

// Meus componentes
import ComplexTable from "../../../components/ComplexTable";

//services
import api from "../../../services/axios";
import Icon from "../../../components/icons";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const CourseList: React.FC = () => {
  const history = useHistory();

  const [companies, setCompanies] = useState([]);
  useEffect(() => {
    api.get("courses").then((response) => {
      setCompanies(response.data);
    });
  }, []);

  return (
    <Container fluid>
      <Card>
        <Card.Header
          as="h5"
          className="d-flex align-items-center justify-content-between"
        >
          Cursos
          <Button
            onClick={() => history.push("/cursos/novo")}
            variant="success"
          >
            <i className="bi bi-plus-lg mr-2"></i>
            Novo curso
          </Button>
        </Card.Header>
        <Card.Body>
          <ComplexTable
            data={companies}
            columns={[
              {
                dataField: "name",
                text: "Nome",
                sort: true,
                filter: true,
              },
              {
                dataField: "id",
                text: "Ações",
                sort: false,
                formatter: (_, row) => (
                  <>
                    <OverlayTrigger
                      key="edit"
                      placement="bottom"
                      overlay={
                        <Tooltip
                          className="color-secondary"
                          id={`tooltip-bottom`}
                        >
                          Editar opção de curso
                        </Tooltip>
                      }
                    >
                      <Icon
                        icon="FaEdit"
                        className="mr-4 color-secondary interaction"
                        size={25}
                        onClick={() => history.push(`cursos/${row.id}`)}
                      />
                    </OverlayTrigger>
                    <OverlayTrigger
                      key="list"
                      placement="bottom"
                      overlay={
                        <Tooltip id={`tooltip-bottom`}>
                          Adicionar uma atividade ao curso
                        </Tooltip>
                      }
                    >
                      <Icon
                        icon="FaFileCirclePlus"
                        className="interaction"
                        size={25}
                        onClick={() =>
                          history.push(`cursos/${row.id}/atividades`, {
                            name: row.name,
                          })
                        }
                      />
                    </OverlayTrigger>
                  </>
                ),
              },
            ]}
          />
        </Card.Body>
      </Card>
    </Container>
  );
};

export default CourseList;
