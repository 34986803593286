import React, { useEffect, useState } from "react";
import { Button, Card, Container, Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import Input from "../../../../components/Input";
import schema from "./schema";
import Select from "../../../../components/Select";
import InputDate from "../../../../components/InputDate";

import api from "src/services/axios";
import { toast } from "react-toastify";
import MaskedInput from "../../../../components/InputMask";
import InputMoney from "../../../../components/InputMoney";
import { yupResolver } from "@hookform/resolvers";
import InputMask from "../../../../components/InputMask";


import { format } from "date-fns";

import CreatableSelect from "../../../../components/CreatableSelect";

import Swal from "sweetalert2";
import supervisorCompanyService from "src/services/SupervisorCompanyService";
import { ProfileReduxState, SelectOption } from "src/types/GlobalTypes";
import {
  DefaultValuesFormSolicitationCompany,
  DefaultValuesFormSolicitationCompanyType,
} from "src/types/SolicitationsTypes";

import educationalInstitutionSupervisorService from "src/services/EducationalInstitutionSupervisorService";
import educationalInstitutionService from "src/services/EducationalInstitutionService";
import courseService from "src/services/CourseService";

export const SolicitationForm = () => {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);

  const [supervisorsCompaniesOptions, setSupervisorsCompaniesOptions] =
    useState<SelectOption[]>([]);

  const [supervisorsEducationalOptions, setSupervisorsEducationalOptions] =
    useState<SelectOption[]>([]);

  const profile: ProfileReduxState = useSelector((state) => state.user.profile);

  const [coursesOptions, setCourses] = useState([]);
  const [educationalInstitutionsOptions, setEducationalInstitutionsOptions] =
    useState([]);

  const { handleSubmit, control, errors, setValue, watch } =
    useForm<DefaultValuesFormSolicitationCompanyType>({
      reValidateMode: "onSubmit",
      resolver: yupResolver(schema),
      defaultValues: {
        [DefaultValuesFormSolicitationCompany.TraineeName]: "",
        [DefaultValuesFormSolicitationCompany.TraineeDocument]: "",
        [DefaultValuesFormSolicitationCompany.Number]: "",
        [DefaultValuesFormSolicitationCompany.CourseId]: null,
        [DefaultValuesFormSolicitationCompany.InternshipScholarshipValue]: 0,
        [DefaultValuesFormSolicitationCompany.TransportationAssistanceValue]: 0,
        [DefaultValuesFormSolicitationCompany.IsHigherEducation]: null,
        [DefaultValuesFormSolicitationCompany.StartValidity]: undefined,
        [DefaultValuesFormSolicitationCompany.Duration]: 0,
        [DefaultValuesFormSolicitationCompany.EducationalInstitutionId]: null,
        [DefaultValuesFormSolicitationCompany.Contact]: "",
        [DefaultValuesFormSolicitationCompany.SupervisorCompanyId]: null,
      },
    });

  const formValues = watch();

  const onSubmit = (data) => {
    setLoading(true);
    const body = {
      duration: data[DefaultValuesFormSolicitationCompany.Duration],
      start_validity: format(
        data[DefaultValuesFormSolicitationCompany.StartValidity],
        "yyyy-MM-dd"
      ),
      is_higher_education:
        data[DefaultValuesFormSolicitationCompany.IsHigherEducation].value,
      number: data[DefaultValuesFormSolicitationCompany.Number],
      course_id: data[DefaultValuesFormSolicitationCompany.CourseId].value,
      trainee_name: data[DefaultValuesFormSolicitationCompany.TraineeName],
      trainee_document:
        data[DefaultValuesFormSolicitationCompany.TraineeDocument],
      internship_scholarship_value:
        data[DefaultValuesFormSolicitationCompany.InternshipScholarshipValue],
      transportation_assistance_value:
        data[
          DefaultValuesFormSolicitationCompany.TransportationAssistanceValue
        ],
      educational_institution_id:
        data[DefaultValuesFormSolicitationCompany.EducationalInstitutionId]
          .value,
      contact: data[DefaultValuesFormSolicitationCompany.Contact],
      supervisor_company_id:
        data[DefaultValuesFormSolicitationCompany.SupervisorCompanyId].value,
      supervisor_educational_institution_id:
        data[DefaultValuesFormSolicitationCompany.SupervisorInstitutionId]
          .value,
    };

    api
      .post("/solicitations", body)
      .then(() => {
        history.goBack();
        toast.success("Solicitação enviada com sucesso!");
      })
      .catch(() => {
        toast.error("Houve um erro ao cadastrar a solicitação!");
      })
      .finally(() => setLoading(false));
  };

  const handleCreateSupervisorCompany = async (inputValue: string) => {
    Swal.fire({
      title: "Qual é a posição do supervisor?",
      input: "text",
      inputAttributes: {
        autocapitalize: "on",
      },
      showCancelButton: true,
      confirmButtonText: "Salvar",
      showLoaderOnConfirm: true,
      preConfirm: async (position) => {
        try {
          const data = await supervisorCompanyService.createSupervisorCompany({
            name: inputValue,
            company_id: profile.company_id,
            position,
          });

          return data;
        } catch (error) {
          Swal.showValidationMessage(`
            Request failed: ${error}
          `);
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      console.log("result", result);
      if (result.isConfirmed) {
        const { value } = result;

        if (value) {
          const option = {
            value: value.id,
            label: value.name,
          };

          setSupervisorsCompaniesOptions([
            ...supervisorsCompaniesOptions,
            option,
          ]);

          setValue(
            DefaultValuesFormSolicitationCompany.SupervisorCompanyId,
            option
          );
        }
      }
    });
  };

  const handleCreateSupervisorEducationalInstitution = async (
    inputValue: string
  ) => {
    Swal.fire({
      title: "Qual é a posição do supervisor?",
      input: "text",
      inputAttributes: {
        autocapitalize: "on",
      },
      showCancelButton: true,
      confirmButtonText: "Salvar",
      showLoaderOnConfirm: true,
      preConfirm: async (position) => {
        try {
          if (
            formValues[
              DefaultValuesFormSolicitationCompany.EducationalInstitutionId
            ]
          ) {
            const data =
              await educationalInstitutionSupervisorService.createSupervisorEducationalInstitution(
                {
                  name: inputValue,
                  educational_institution_id: formValues[
                    DefaultValuesFormSolicitationCompany
                      .EducationalInstitutionId
                  ].value as number,
                  position,
                }
              );

            return data;
          }
        } catch (error) {
          Swal.showValidationMessage(`
            Request failed: ${error}
          `);
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      console.log("result", result);
      if (result.isConfirmed) {
        const { value } = result;

        if (value) {
          const option = {
            value: value.id,
            label: value.name,
          };

          setSupervisorsEducationalOptions([
            ...supervisorsEducationalOptions,
            option,
          ]);

          setValue(
            DefaultValuesFormSolicitationCompany.SupervisorInstitutionId,
            option
          );
        }
      }
    });
  };

  useEffect(() => {
    if (
      formValues[DefaultValuesFormSolicitationCompany.EducationalInstitutionId]
    ) {
      api
        .get(
          `/supervisors/educational_institutions?educational_institution_id=${
            formValues[
              DefaultValuesFormSolicitationCompany.EducationalInstitutionId
            ].value
          }`
        )
        .then((response) => {
          setSupervisorsEducationalOptions(
            response.data.map((item) => {
              return {
                value: item.id,
                label: item.name,
              };
            })
          );
        });
    }
  }, [
    formValues[DefaultValuesFormSolicitationCompany.EducationalInstitutionId],
  ]);

  useEffect(() => {
    educationalInstitutionService.fetchAll().then((data) => {
      setEducationalInstitutionsOptions(data.map((item) => ({
        value: item.id,
        label: item.name,
      })));
    });
  }, []);

  useEffect(() => {
    courseService.fetchAll().then((data) => {
      setCourses(data.map((item) => ({
        value: item.id,
        label: item.name,
      })));
    });
  }, []);

  useEffect(() => {
    supervisorCompanyService.fetchByCompanyId(1).then((data) => {
      setSupervisorsCompaniesOptions(data.map((item) => ({
        value: item.id,
        label: item.name,
      })));
    });
  }, []);

  return (
    <Container fluid className="mt-3">
      <Card>
        <Card.Header as="h5">SOLICITAÇÃO - CONTRATO DE ESTÁGIO</Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
              <Controller
                as={Input}
                control={control}
                name={DefaultValuesFormSolicitationCompany.TraineeName}
                label="Nome do estagiário"
                errorMessage={
                  errors[DefaultValuesFormSolicitationCompany.TraineeName]
                    ?.message
                }
                md="4"
                required
              />
              <Controller
                as={MaskedInput}
                control={control}
                name={DefaultValuesFormSolicitationCompany.TraineeDocument}
                mask="cpf"
                label="CPF"
                errorMessage={
                  errors[DefaultValuesFormSolicitationCompany.TraineeDocument]
                    ?.message
                }
                md="4"
                required
              />
              <Controller
                as={Select}
                control={control}
                name={DefaultValuesFormSolicitationCompany.IsHigherEducation}
                label="É ensino superior ?"
                errorMessage={
                  errors[DefaultValuesFormSolicitationCompany.IsHigherEducation]
                    ?.message
                }
                md="4"
                options={[
                  {
                    value: true,
                    label: "Sim",
                  },
                  {
                    value: false,
                    label: "Não",
                  },
                ]}
                required
              />
            </Form.Row>
            <Form.Row>
              <Controller
                as={InputMask}
                mask="cel"
                control={control}
                name={DefaultValuesFormSolicitationCompany.Contact}
                label="Telefone"
                md="4"
              />
              <Controller
                as={InputDate}
                control={control}
                name={DefaultValuesFormSolicitationCompany.StartValidity}
                label="Período vigência - Início"
                errorMessage={
                  errors[DefaultValuesFormSolicitationCompany.StartValidity]
                    ?.message
                }
                md="4"
                required
              />
              <Controller
                as={Input}
                type="number"
                control={control}
                name={DefaultValuesFormSolicitationCompany.Duration}
                label="Jornada em horas"
                errorMessage={
                  errors[DefaultValuesFormSolicitationCompany.Duration]?.message
                }
                md="4"
                required
              />
            </Form.Row>

            <Form.Row>
              <Controller
                as={Select}
                control={control}
                name={DefaultValuesFormSolicitationCompany.CourseId}
                label="Qual o curso ?"
                errorMessage={
                  errors[DefaultValuesFormSolicitationCompany.CourseId]?.message
                }
                md="6"
                options={coursesOptions}
                required
              />
              <Controller
                as={Select}
                control={control}
                name={
                  DefaultValuesFormSolicitationCompany.EducationalInstitutionId
                }
                label="Qual a instituição ?"
                errorMessage={
                  errors[
                    DefaultValuesFormSolicitationCompany
                      .EducationalInstitutionId
                  ]?.message
                }
                md="6"
                options={educationalInstitutionsOptions}
                required
              />
            </Form.Row>
            <Form.Row>
              <Controller
                as={InputMoney}
                control={control}
                name={
                  DefaultValuesFormSolicitationCompany.InternshipScholarshipValue
                }
                label="Valor da bolsa"
                errorMessage={
                  errors[
                    DefaultValuesFormSolicitationCompany
                      .InternshipScholarshipValue
                  ]?.message
                }
                md="6"
                required
              />
              <Controller
                as={InputMoney}
                control={control}
                name={
                  DefaultValuesFormSolicitationCompany.TransportationAssistanceValue
                }
                label="Auxilio transporte"
                errorMessage={
                  errors[
                    DefaultValuesFormSolicitationCompany
                      .TransportationAssistanceValue
                  ]?.message
                }
                md="6"
                required
              />
            </Form.Row>
            <Form.Row>
              <Controller
                as={CreatableSelect}
                handleCreateNewOption={
                  handleCreateSupervisorEducationalInstitution
                }
                control={control}
                name={
                  DefaultValuesFormSolicitationCompany.SupervisorInstitutionId
                }
                label="Qual o supervisor da instituição de ensino "
                md="6"
                options={supervisorsEducationalOptions}
                placeholder={
                  !formValues[
                    DefaultValuesFormSolicitationCompany
                      .EducationalInstitutionId
                  ]
                    ? "Selecione uma instituição de ensino antes"
                    : "Selecione"
                }
                required
                isDisabled={
                  !formValues[
                    DefaultValuesFormSolicitationCompany
                      .EducationalInstitutionId
                  ]
                }
                errorMessage={
                  errors[
                    DefaultValuesFormSolicitationCompany.SupervisorInstitutionId
                  ]?.message
                }
              />

              <Controller
                as={CreatableSelect}
                handleCreateNewOption={handleCreateSupervisorCompany}
                control={control}
                name={DefaultValuesFormSolicitationCompany.SupervisorCompanyId}
                label="Qual o supervisor da empresa "
                md="6"
                options={supervisorsCompaniesOptions}
                required
                errorMessage={
                  errors[
                    DefaultValuesFormSolicitationCompany.SupervisorCompanyId
                  ]?.message
                }
              />
            </Form.Row>
            <Button
              type="submit"
              className="mr-3"
              disabled={loading}
              variant="success"
            >
              Enviar
            </Button>
            <Button onClick={() => history.goBack()} variant="danger">
              Cancelar
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};
