import api from "./axios";

import {
  ResponseCreateSupervisorEducationalInstitution,
  BodyCreateSupervisorEducationalInstitution,
} from "src/types/EducationalInstitutionSupervisorTypes";

class EducationalInstitutionSupervisorService {
  private endpoint = "/supervisors/educational_institutions";

  async createSupervisorEducationalInstitution(
    body: BodyCreateSupervisorEducationalInstitution
  ) {
    const { data } = await api.post(this.endpoint, body);
    return data as ResponseCreateSupervisorEducationalInstitution;
  }

  async fetchByEducationalInstitutionId(educationalInstitutionId: number) {
    const { data } = await api.get(`${this.endpoint}?educational_institution_id=${educationalInstitutionId}`);
    return data;
  }
}

const educationalInstitutionSupervisorService =
  new EducationalInstitutionSupervisorService();

export default educationalInstitutionSupervisorService;
