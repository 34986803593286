import React from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Label from "../Label";

import  CreatableSelectComponent  from 'react-select/creatable';

import "../Select/styles.css";

interface SelectProps {
  label: string;
  name: string;
  handleCreateNewOption(inputValue: string): Promise<void>;
  errorMessage?: string;
  md?: string;
  options: any;
  error?: boolean;
  required?: boolean;
  isClearable?: boolean;
  isMulti?: boolean;
  isDisabled?: boolean
  placeholder?: string;
}

const CreatableSelect: React.FC<SelectProps> = ({
  md = 4,
  label,
  errorMessage = "",
  options,
  required = false,
  isClearable = true,
  isMulti = false,
  handleCreateNewOption,
  placeholder = "Selecione",
  ...rest
}) => {

  // Estilos customizados para o ReactSelect
  const customStyles = {
    // Contêiner principal da área de input
    control: (base: any, state: any) => ({
      ...base,
      backgroundColor: "var(--bg-secondary)", // Fundo do input
      borderColor: errorMessage
        ? "red" // Vermelho se houver erro
        : state.isFocused
        ? "var(--secondary)" // Cor ao focar
        : "var(--slate-300)", // Cor padrão da borda
      "&:hover": {
        borderColor: "var(--secondary)", // Borda ao passar o mouse
      },
      boxShadow: state.isFocused ? "0 0 0 1px var(--secondary)" : "none", // Sombra quando focado
    }),
  
    // Menu suspenso das opções
    menu: (base: any) => ({
      ...base,
      backgroundColor: "var(--bg-default)", // Fundo do menu
      border: "1px solid var(--slate-300)", // Borda do menu
    }),
  
    // Cada opção individual no menu suspenso
    option: (base: any, state: any) => ({
      ...base,
      backgroundColor: state.isFocused
        ? "var(--bg-secondary)" // Fundo ao focar na opção
        : "var(--bg-default)", // Fundo padrão
      color: "var(--color-text)", // Cor do texto da opção
    }),
  
    // Valor selecionado na área de input
    singleValue: (base: any) => ({
      ...base,
      color: "var(--color-text)", // Cor do texto do valor selecionado
    }),
  
    // Placeholder (texto exibido quando nenhum valor é selecionado)
    placeholder: (base: any) => ({
      ...base,
      color: "var(--color-text)", // Cor do texto do placeholder
    }),
  
    // Ícone de seta que abre o menu
    dropdownIndicator: (base: any) => ({
      ...base,
      color: "var(--color-text)", // Cor do ícone de seta
    }),
  
    // Texto digitado pelo usuário no campo de input
    input: (base: any) => ({
      ...base,
      color: "var(--color-text)", // Cor do texto digitado no input
    }),
  };



  return (
    <Form.Group as={Col} md={md} controlId={`form${label}`}>
      <Label text={label} required={required} />

      <CreatableSelectComponent 
        styles={customStyles}
        placeholder={placeholder}
        options={options}
        isClearable={isClearable}
        isMulti={isMulti}
        onCreateOption={handleCreateNewOption}
        formatCreateLabel={userInput => `Não encontrado essa opção. Criar opção ${userInput} ?`}
        {...rest}
      />
      {errorMessage && <div className="invalid-custom">{errorMessage}</div>}
    </Form.Group>
  );
};

export default CreatableSelect;
