import api from "./axios";

class CourseService {
  private endpoint = "/courses";

  async fetchAll() {
    const { data } = await api.get(this.endpoint);
    return data;
  }
}

const courseService = new CourseService();

export default courseService;