/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import { parseISO } from "date-fns";
import { yupResolver } from "@hookform/resolvers";

import api from "src/services/axios";

import Input from "../../../components/Input";
import InputMoney from "../../../components/InputMoney";
import InputDate from "../../../components/InputDate";
import Textarea from "../../../components/Textarea";
import Select from "../../../components/Select";

import schema from "./schema";
import { toast } from "react-toastify";
import AsyncSelectComponent from "../../../components/Async";

interface Option {
  value: string | boolean;
  label: string;
}

interface ContractData {
  trainee_id: Option | null;
  educational_institution_id: Option | null;
  company_id: Option | null;
  start_validity: Date | undefined;
  end_validity: Date | undefined;
  duration: number;
  work_activities: string;
  internship_scholarship_value: number;
  transportation_assistance_value: number;
  course_id: { value: string; label: string } | null;
  activies_id: { value: string; label: string }[] | null;
  insurance_number: string;
  is_higher_education: null | Option;
  supervisor_company: Option | null;
  supervisor_education: Option | null;
}

interface Company {
  id: number;
  cnpj: string;
  fantasy_name: string;
  group?: {
    id: 1;
    name: string;
  };
}

const higherEducationOptions = [
  {
    value: true,
    label: "Sim",
  },
  {
    value: false,
    label: "Não",
  },
];

const ContratoForm: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();

  const [dataCurrentCompany, setDataCurrentCompany] = useState<Company | null>(
    null
  );

  const params = useParams();

  const [supervisorsCompaniesOptions, setSupervisorsCompaniesOptions] =
    useState([]);
  const [supervisorsEducationalOptions, setSupervisorsEducationalOptions] =
    useState([]);
  const [educationalInstitutionsOptions, setEducationalInstitutionsOptions] =
    useState([]);
  const [coursesOptions, setCourses] = useState([]);

  const [currentActivies, setCurrentActivies] = useState([]);

  const { handleSubmit, control, watch, errors, setValue } =
    useForm<ContractData>({
      reValidateMode: "onSubmit",
      resolver: yupResolver(schema),
      defaultValues: {
        trainee_id: null,
        educational_institution_id: null,
        company_id: null,
        start_validity: undefined,
        end_validity: undefined,
        duration: 0,
        work_activities: "",
        internship_scholarship_value: 0,
        transportation_assistance_value: 0,
        course_id: null,
        activies_id: null,
        insurance_number: "",
        is_higher_education: {
          value: false,
          label: "Não",
        },
        supervisor_company: null,
        supervisor_education: null,
      },
    });

  const courseId = watch("course_id");
  const activiesId = watch("activies_id");
  const company = watch("company_id");
  const educationalInstitutionId = watch("educational_institution_id");

  useEffect(() => {
    if (company)
      api
        .get(`/supervisors/companies?company_id=${company.value}`)
        .then((response) => {
          setSupervisorsCompaniesOptions(
            response.data.map((item) => {
              return {
                value: item.id,
                label: item.name,
              };
            })
          );
        });
  }, [company]);

  useEffect(() => {
    if (educationalInstitutionId)
      api
        .get(
          `/supervisors/educational_institutions?educational_institution_id=${educationalInstitutionId.value}`
        )
        .then((response) => {
          setSupervisorsEducationalOptions(
            response.data.map((item) => {
              return {
                value: item.id,
                label: item.name,
              };
            })
          );
        });
  }, [educationalInstitutionId]);

  useEffect(() => {
    api.get("educational_institutions").then((response) => {
      setEducationalInstitutionsOptions(
        response.data.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        })
      );
    });
  }, []);

  useEffect(() => {
    api.get("courses").then((response) => {
      setCourses(
        response.data.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        })
      );
    });
  }, []);

  const onSubmit = (data) => {
    setLoading(true);
    const dataContract = {
      trainee_id: data.trainee_id.value,
      company_id: data.company_id.value,
      start_validity: data.start_validity,
      end_validity: data.end_validity,
      insurance_number: data.insurance_number,
      internship_scholarship_value: data.internship_scholarship_value,
      transportation_assistance_value: data.transportation_assistance_value,
      duration: data.duration,
      course_id: data.course_id.value,
      educational_institution_id: data.educational_institution_id.value,
      work_activities: data.work_activities,
      is_higher_education: data.is_higher_education.value,
      supervisor_company_id: data.supervisor_company?.value,
      supervisor_educational_institution_id: data.supervisor_education?.value,
    };

    if (params?.id) {
      api
        .put(`/contracts/${params.id}`, dataContract)
        .then(() => {
          history.goBack();
          toast.success("Contrato atualizado com sucesso!");
        })
        .catch(() => {
          toast.error("Houve um erro ao atualizar o contrato!");
        });
    } else {
      api
        .post("/contracts", dataContract)
        .then(() => {
          history.goBack();
          toast.success("Contrato estabelecido com sucesso!");
        })
        .catch(() => {
          toast.error("Houve um erro ao estabelecer o contrato!");
        });
    }
  };

  useEffect(() => {
    if (params.id) {
      api.get(`/contracts/${params.id}`).then((response) => {
        setValue(
          "start_validity",
          parseISO(response.data.start_validity.substring(0, 10))
        );
        setValue(
          "end_validity",
          parseISO(response.data.end_validity.substring(0, 10))
        );
        setValue("work_activities", response.data.work_activities);
        setValue(
          "internship_scholarship_value",
          response.data.internship_scholarship_value
        );
        setValue(
          "transportation_assistance_value",
          response.data.transportation_assistance_value
        );
        setValue("duration", response.data.duration);
        setValue("insurance_number", response.data.insurance_number);
        setValue("trainee_id", {
          value: response.data.trainee?.id,
          label: response.data.trainee?.name,
        });
        setValue("educational_institution_id", {
          value: response.data.educational_institution?.id,
          label: response.data.educational_institution?.name,
        });
        setValue("company_id", {
          value: response.data.company?.id,
          label: response.data.company?.company_name,
        });
        setValue("course_id", {
          value: response.data.course?.id,
          label: response.data.course?.name,
        });
        setValue(
          "is_higher_education",
          higherEducationOptions.find(
            (item) => item.value === response.data.is_higher_education
          )
        );
      });
    }
  }, [params.id]);

  useEffect(() => {
    if (courseId) {
      api
        .get(`courseActivities?course_id=${courseId.value}`)
        .then((response) => {
          setCurrentActivies(
            response.data.map((item) => {
              return {
                value: item.id,
                label: item.description,
              };
            })
          );
        });
    }
  }, [courseId]);

  useEffect(() => {
    if (activiesId) {
      let text: string = "";

      if (activiesId.length === 1) {
        text = activiesId[0].label;
      } else {
        // eslint-disable-next-line array-callback-return
        activiesId.map((element, index) => {
          if (index === activiesId.length) {
            text = `${text} e ${element.label}`;
          } else {
            text = `${text}, ${element.label}`;
          }
        });
      }

      setValue("work_activities", text);
    }
  }, [activiesId]);

  useEffect(() => {
    if (company) {
      api
        .get(`/companies/${company?.value}`)
        .then((response) => {
          setDataCurrentCompany(response.data);
        })
        .catch(() => toast.error("Erro ao buscar informações da empresa"));
    }
  }, [company]);

  const getOptionsCompanies = async (value: string, callback) => {
    if (!value || value.length <= 5) {
      callback([]);
    }
    const response = await api.get(`companies?nome=${value}`);

    const options = response.data.map((item) => {
      return {
        value: item.id,
        label: item.company_name,
      };
    });

    callback(options);
  };

  const getOptions = async (value, callback) => {
    if (!value || value.length <= 5) {
      callback([]);
    }
    const response = await api.get(`trainees?nome=${value}`);

    const options = response.data.map((item) => {
      return {
        value: item.id,
        label: item.name,
        extras: {
          admission_date: item.admission_date,
        },
      };
    });
    callback(options);
  };

  return (
    <Container fluid>
      <Card>
        <Card.Header as="h5">Geração de contrato</Card.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Card.Body>
            {dataCurrentCompany && (
              <Card.Header>
                Nome fantasia:{" "}
                {dataCurrentCompany?.fantasy_name || "Não informado"}
                <strong> | </strong> CNPJ: {dataCurrentCompany?.cnpj}
                <strong> | </strong> Grupo:{" "}
                {dataCurrentCompany?.group?.name || "Não informado"}
              </Card.Header>
            )}
            <Form.Row>
              <Controller
                as={AsyncSelectComponent}
                control={control}
                name="company_id"
                label="Qual empresa ?"
                md="6"
                errorMessage={errors.company_id?.message}
                loadOptions={getOptionsCompanies}
                required
              />
              <Controller
                as={AsyncSelectComponent}
                control={control}
                name="trainee_id"
                label="Qual o estagiário ?"
                md="6"
                errorMessage={errors.trainee_id?.message}
                loadOptions={getOptions}
                required
              />
            </Form.Row>
            <Form.Row>
              <Controller
                as={InputDate}
                control={control}
                name="start_validity"
                label="Período vigência - Início"
                md="4"
                required
                errorMessage={errors.start_validity?.message}
              />
              <Controller
                as={InputDate}
                control={control}
                name="end_validity"
                label="Período vigência - Fim"
                md="4"
                errorMessage={errors.end_validity?.message}
                required
              />
              <Controller
                as={Input}
                control={control}
                name="insurance_number"
                label="Nº do seguro"
                md="4"
              />
            </Form.Row>
            <Form.Row>
              <Controller
                as={InputMoney}
                control={control}
                name="internship_scholarship_value"
                label="Valor da bolsa"
                md="4"
                error={errors.internship_scholarship_value?.message}
                required
              />
              <Controller
                as={InputMoney}
                control={control}
                name="transportation_assistance_value"
                label="Auxilio transporte"
                md="4"
                error={errors.transportation_assistance_value?.message}
                required
              />
              <Controller
                as={Input}
                type="number"
                control={control}
                name="duration"
                errorMessage={errors.duration?.message}
                label="Jornada em horas"
                md="4"
                required
              />
            </Form.Row>
            <Form.Row>
              <Controller
                as={Select}
                control={control}
                name="educational_institution_id"
                label="Qual a instituição ?"
                md="8"
                errorMessage={errors.educational_institution_id?.message}
                options={educationalInstitutionsOptions}
                required
              />
              <Controller
                as={Select}
                control={control}
                name="is_higher_education"
                label="É ensino superior ?"
                md="4"
                options={higherEducationOptions}
                errorMessage={errors.is_higher_education?.message}
                required
              />
            </Form.Row>
            <Form.Row>
              <Controller
                as={Select}
                control={control}
                name="course_id"
                label="Qual o curso ?"
                md="12"
                errorMessage={errors.course_id?.message}
                options={coursesOptions}
                required
              />
            </Form.Row>
            <Form.Row>
              <Controller
                as={Select}
                control={control}
                name="activies_id"
                label="Qual as atividades ?"
                md="12"
                options={currentActivies}
                isMulti
                required
              />
            </Form.Row>
            <Form.Row>
              <Controller
                as={Textarea}
                control={control}
                name="work_activities"
                errorMessage={errors.work_activities?.message}
                label="Atividades"
                md="12"
                required
              />
            </Form.Row>
            <Form.Row>
              <Controller
                as={Select}
                control={control}
                name="supervisor_education"
                label="Qual supervisor da educação ?"
                md="6"
                options={supervisorsEducationalOptions}
                required
              />
              <Controller
                as={Select}
                control={control}
                name="supervisor_company"
                label="Qual a supervisor da empresa ?"
                md="6"
                options={supervisorsCompaniesOptions}
                required
              />
            </Form.Row>
          </Card.Body>
          <Card.Footer>
            <Form.Row>
              <Button
                className="mr-3"
                variant="success"
                disabled={loading}
                type="submit"
              >
                {loading ? "Carregando..." : "Salvar"}
              </Button>
              <Button onClick={() => history.goBack()} variant="danger">
                Cancelar
              </Button>
            </Form.Row>
          </Card.Footer>
        </Form>
      </Card>
    </Container>
  );
};

export default ContratoForm;
