import { BodyCreateSupervisorCompany, ResponseCreateSupervisorCompany } from "src/types/SupervisorCompanyTypes";
import api from "./axios";

class SupervisorCompanyService {
  private endpoint = "/supervisors/companies";

  async createSupervisorCompany(body: BodyCreateSupervisorCompany) {
    const  {data}  = await api.post(this.endpoint, body);
    return data as ResponseCreateSupervisorCompany
  }

  async fetchByCompanyId(companyId: number) {
    const { data } = await api.get(`${this.endpoint}?company_id=${companyId}`);
    return data;
  }
  
}

const supervisorCompanyService = new SupervisorCompanyService();

export default supervisorCompanyService;
