import { SelectOption } from "./GlobalTypes";

export enum DefaultValuesFormSolicitationCompany {
  TraineeName = "trainee_name",
  TraineeDocument = "trainee_document",
  Number = "number",
  CourseId = "course_id",
  InternshipScholarshipValue = "internship_scholarship_value",
  TransportationAssistanceValue = "transportation_assistance_value",
  IsHigherEducation = "is_higher_education",
  StartValidity = "start_validity",
  Duration = "duration",
  EducationalInstitutionId = "educational_institution_id",
  Contact = "contact",
  SupervisorCompanyId = "supervisor_company_id",
  SupervisorInstitutionId = "supervisor_institution_id",
}

export type DefaultValuesFormSolicitationCompanyType = {
  [DefaultValuesFormSolicitationCompany.TraineeName]: "";
  [DefaultValuesFormSolicitationCompany.TraineeDocument]: "";
  [DefaultValuesFormSolicitationCompany.Number]: "";
  [DefaultValuesFormSolicitationCompany.CourseId]: SelectOption | null;
  [DefaultValuesFormSolicitationCompany.InternshipScholarshipValue]: 0;
  [DefaultValuesFormSolicitationCompany.TransportationAssistanceValue]: 0;
  [DefaultValuesFormSolicitationCompany.IsHigherEducation]: SelectOption | null;
  [DefaultValuesFormSolicitationCompany.StartValidity]: undefined;
  [DefaultValuesFormSolicitationCompany.Duration]: 0;
  [DefaultValuesFormSolicitationCompany.EducationalInstitutionId]: SelectOption | null;
  [DefaultValuesFormSolicitationCompany.Contact]: "";
  [DefaultValuesFormSolicitationCompany.SupervisorCompanyId]: SelectOption | null;
  [DefaultValuesFormSolicitationCompany.SupervisorInstitutionId]: SelectOption | null;
};

export type ResponseFetchOneSolicitation = {
  id: number;
  application_id: string;
  internship_scholarship_value: number;
  transportation_assistance_value: number;
  is_higher_education: boolean;
  course_id: number;
  company_id: number;
  trainee_name: string;
  trainee_document: string;
  start_validity: string;
  educational_institution_id: number;
  duration: string;
  created_at: string;
  updated_at: string;
  contact: string;
  supervisor_company_id: number;
  supervisor_educational_institution_id: number;
  company: {
    id: number;
    cnpj: string;
    company_name: string;
    contact: string;
    cep: string;
    address: string;
    neighborhood: string;
    city: string;
    state: string;
    number: string;
    complement: string | null;
    rh_analyst: string;
    supervisor: string;
    integration_agent_value: number;
    institution_value: number;
    created_at: string;
    updated_at: string;
    agreement_start_date: string;
    application_id: string;
    type_charge: string;
    fantasy_name: string | null;
    group_id: string | null;
    integration_agent_higher_education_value: number | null;
    amount_clt: number | null;
    email_signature: string;
  };
  course: {
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
    application_id: string;
  };
  educational_institution: {
    id: number;
    name: string;
    cnpj: string;
    contact: string;
    cep: string;
    address: string;
    neighborhood: string;
    city: string;
    state: string;
    number: string;
    complement: string;
    created_at: string;
    updated_at: string;
    application_id: string;
    email_signature: string | null;
  };
  supervisor_company: {
    id: number;
    application_id: string;
    company_id: number;
    name: string;
    position: string;
    created_at: string;
    updated_at: string;
  };
  supervisor_educational_institution: {
    id: number;
    application_id: string;
    educational_institution_id: number;
    name: string;
    position: string;
    created_at: string;
    updated_at: string;
  };
  trainee: {
    id: number;
    cpf: string;
    name: string;
    rg: string;
    primary_phone_contact: string;
    secondary_phone_contact: string;
    gender: string;
    date_birth: string;
    cep: string;
    address: string;
    neighborhood: string;
    city: string;
    state: string;
    number: string;
    complement: string;
    father_name: string;
    mother_name: string;
    have_special_needs: boolean;
    created_at: string;
    updated_at: string;
    email: string;
    insurance_number: string | null;
    application_id: string;
    document_responsible: string;
    age: number;
  };
};

export enum DefaultValuesFormAnalysisSolicitationCompanyEnum {
  TRAINEE_ID = "trainee_id",
  EDUCATIONAL_INSTITUTION_ID = "educational_institution_id",
  COMPANY_ID = "company_id",
  START_VALIDITY = "start_validity",
  END_VALIDITY = "end_validity",
  DURATION = "duration",
  WORK_ACTIVITIES = "work_activities",
  INTERNSHIP_SCHOLARSHIP_VALUE = "internship_scholarship_value",
  TRANSPORTATION_ASSISTANCE_VALUE = "transportation_assistance_value",
  COURSE_ID = "course_id",
  ACTIVIES_ID = "activies_id",
  INSURANCE_NUMBER = "insurance_number",
  IS_HIGHER_EDUCATION = "is_higher_education",
  SUPERVISOR_COMPANY = "supervisor_company",
  SUPERVISOR_EDUCATION = "supervisor_education",
  CONTACT = "contact",
}

export type DefaultValuesFormAnalysisSolicitationCompanyType = {
  [DefaultValuesFormAnalysisSolicitationCompanyEnum.TRAINEE_ID]: SelectOption | null;
  [DefaultValuesFormAnalysisSolicitationCompanyEnum.EDUCATIONAL_INSTITUTION_ID]: SelectOption | null;
  [DefaultValuesFormAnalysisSolicitationCompanyEnum.COMPANY_ID]: SelectOption | null;
  [DefaultValuesFormAnalysisSolicitationCompanyEnum.START_VALIDITY]:
    | Date
    | undefined;
  [DefaultValuesFormAnalysisSolicitationCompanyEnum.END_VALIDITY]:
    | Date
    | undefined;
  [DefaultValuesFormAnalysisSolicitationCompanyEnum.DURATION]: number;
  [DefaultValuesFormAnalysisSolicitationCompanyEnum.WORK_ACTIVITIES]: string;
  [DefaultValuesFormAnalysisSolicitationCompanyEnum.INTERNSHIP_SCHOLARSHIP_VALUE]: number;
  [DefaultValuesFormAnalysisSolicitationCompanyEnum.TRANSPORTATION_ASSISTANCE_VALUE]: number;
  [DefaultValuesFormAnalysisSolicitationCompanyEnum.COURSE_ID]: SelectOption | null;
  [DefaultValuesFormAnalysisSolicitationCompanyEnum.ACTIVIES_ID]:
    | SelectOption[]
    | null;
  [DefaultValuesFormAnalysisSolicitationCompanyEnum.INSURANCE_NUMBER]: string;
  [DefaultValuesFormAnalysisSolicitationCompanyEnum.IS_HIGHER_EDUCATION]: {
    label: string;
    value: boolean;
  } | null;
  [DefaultValuesFormAnalysisSolicitationCompanyEnum.SUPERVISOR_COMPANY]: SelectOption | null;
  [DefaultValuesFormAnalysisSolicitationCompanyEnum.SUPERVISOR_EDUCATION]: SelectOption | null;
  [DefaultValuesFormAnalysisSolicitationCompanyEnum.CONTACT]: string;
};
