import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";

import { parseISO } from "date-fns";

import { toast } from "react-toastify";

// Importações do react-bootrap
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import swal from "sweetalert";
import axios from "axios";
import api from "src/services/axios";

// Componentes da aplicação
import MaskedInput from "../../../components/InputMask";
import Input from "../../../components/Input";
import Select from "../../../components/Select";
import InputDate from "../../../components/InputDate";

import theme from "src/styles/theme";
import schema from "./schema";

const genderOptions = [
  {
    value: "M",
    label: "Masculino",
  },
  {
    value: "F",
    label: "Feminino",
  },
];

const have_special_needs = [
  {
    value: true,
    label: "Sim",
  },
  {
    value: false,
    label: "Não",
  },
];

interface DefautlValue {
  cpf: string;
  name: string;
  rg: string;
  primary_phone_contact: string;
  secondary_phone_contact: string;
  gender: string | object;
  date_birth: undefined | Date;
  cep: string;
  address: string;
  neighborhood: string;
  city: string;
  state: string;
  number: string;
  complement: string;
  father_name: string;
  mother_name: string;
  have_special_needs: object;
  email: string;
}

const defaultValues: DefautlValue = {
  cpf: "",
  name: "",
  rg: "",
  primary_phone_contact: "",
  secondary_phone_contact: "",
  gender: "",
  date_birth: undefined,
  cep: "",
  address: "",
  neighborhood: "",
  city: "",
  state: "",
  number: "",
  complement: "",
  father_name: "",
  mother_name: "",
  have_special_needs: {
    value: false,
    label: "Não é portador de necessidades especiais",
  },
  email: "",
};

const EstagiarioForm: React.FC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const params = useParams();

  const { handleSubmit, control, watch, setValue, errors } = useForm({
    reValidateMode: "onSubmit",
    resolver: yupResolver(schema),
    defaultValues,
  });

  const watchCep = watch("cep");
  const cpf = watch("cpf");

  useEffect(() => {
    if (watchCep.length === 9 && !params.id) {
      swal({
        title: "Buscar CEP automaticamente ?",
        icon: "warning",
        buttons: ["Cancelar", true],
      }).then((value) => {
        if (value) {
          axios
            .get(`https://viacep.com.br/ws/${watchCep}/json/`)
            .then((response) => {
              setValue("neighborhood", response.data?.bairro);
              setValue("address", response.data?.logradouro);
              setValue("city", response.data?.localidade);
              setValue("state", response.data?.uf);
              setValue("complement", response.data?.complemento);
            });
        }
      });
    }
  }, [watchCep, setValue, params.id]);

  useEffect(() => {
    if (!params?.id) {
      if (cpf.length === 14) {
        api.get(`/trainees?cpf=${cpf}`).then((response) => {
          if (response.data.length > 0)
            toast.error("oops! Esse cpf já está na base de dados");
        });
      }
    }
  }, [cpf, params]);

  useEffect(() => {
    if (params.id) {
      api.get(`/trainees/${params.id}`).then((response) => {
        setValue("cpf", response.data.cpf);
        setValue("name", response.data.name);
        setValue("rg", response.data.rg);
        setValue("primary_phone_contact", response.data.primary_phone_contact);
        setValue(
          "secondary_phone_contact",
          response.data.secondary_phone_contact
        );
        setValue(
          "gender",
          response.data.gender === "M" ? genderOptions[0] : genderOptions[1]
        );
        setValue(
          "date_birth",
          response.data.date_birth.substring(0, 10)
            ? parseISO(response.data.date_birth.substring(0, 10))
            : ""
        );
        setValue("cep", response.data.cep || "");
        setValue("address", response.data.address);
        setValue("neighborhood", response.data.neighborhood);
        setValue("state", response.data.state);
        setValue("city", response.data.city);
        setValue("number", response.data.number);
        setValue("complement", response.data.complement);
        setValue("father_name", response.data.father_name);
        setValue("mother_name", response.data.mother_name);
        setValue(
          "have_special_needs",
          response.data.have_special_needs
            ? have_special_needs[0]
            : have_special_needs[1]
        );
        setValue("email", response.data.email);
        setValue("document_responsible", response.data.document_responsible);
      });
    }
  }, [params.id, setValue]);

  const onSubmit = (data) => {
    setLoading(true);
    const trainee = {
      cpf: data.cpf,
      name: data.name,
      rg: data.rg,
      primary_phone_contact: data.primary_phone_contact,
      secondary_phone_contact: data.secondary_phone_contact,
      gender: data.gender?.value,
      date_birth: data.date_birth,
      cep: data.cep,
      address: data.address,
      neighborhood: data.neighborhood,
      city: data.city,
      state: data.state,
      number: data.number,
      complement: data.complement,
      father_name: data.father_name,
      mother_name: data.mother_name,
      have_special_needs: data.have_special_needs.value,
      email: data.email,
      document_responsible: data.document_responsible,
    };

    if (params?.id) {
      api
        .put(`/trainees/${params.id}`, trainee)
        .then((response) => {
          history.goBack();
          toast.success("Atualizado com sucesso!");
        })
        .catch(() => {
          toast.error("Houve um erro ao atualizar o estagiário!");
        });
    } else {
      api
        .post("/trainees/intern", trainee)
        .then((response) => {
          history.goBack();
          toast.success("Cadastrado com sucesso!");
        })
        .catch(() => {
          toast.error("Houve um erro ao cadastrar o estagiário!");
        });
    }
  };

  return (
    <Container fluid>
      <Card>
        <Card.Header as="h5">Novo Estagiário</Card.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Card.Body>
            <Form.Row>
              <Controller
                as={MaskedInput}
                control={control}
                name="cpf"
                mask="cpf"
                label="CPF"
                errorMessage={errors.cpf?.message}
                md="3"
                required
              />
              <Controller
                as={Input}
                control={control}
                name="name"
                label="Nome"
                errorMessage={errors.name?.message}
                md="6"
                required
              />
              <Controller
                as={Input}
                control={control}
                name="rg"
                label="RG"
                md="3"
              />
            </Form.Row>

            <Form.Row>
              <Controller
                as={MaskedInput}
                control={control}
                name="primary_phone_contact"
                mask="cel"
                label="Contato principal"
                md="3"
              />
              <Controller
                as={MaskedInput}
                control={control}
                name="secondary_phone_contact"
                mask="cel"
                label="Contato Secundário"
                md="3"
              />

              <Controller
                as={Select}
                control={control}
                name="gender"
                label="Sexo"
                options={genderOptions}
                md="3"
              />
              <Controller
                as={InputDate}
                control={control}
                name="date_birth"
                label="Nascimento"
                md="3"
              />
            </Form.Row>
            <hr />
            <Form.Row>
              <Controller
                as={Input}
                control={control}
                name="father_name"
                label="Nome do responsável"
                md="6"
              />
              {/* <Controller
                as={Input}
                control={control}
                name="mother_name"
                label="Nome da mãe"
                md="4"
              /> */}

              <Controller
                as={MaskedInput}
                mask="cpf"
                control={control}
                name="document_responsible"
                label="CPF do responsável"
                md="4"
              />
            </Form.Row>

            <hr />
            <Form.Row>
              <Controller
                as={MaskedInput}
                control={control}
                name="cep"
                mask="cep"
                label="CEP"
                md="3"
              />
              <Controller
                as={Input}
                control={control}
                name="address"
                label="Endereço"
                md="3"
              />
              <Controller
                as={Input}
                control={control}
                name="neighborhood"
                label="Bairro"
                md="3"
              />
              <Controller
                as={Input}
                control={control}
                name="city"
                label="Cidade"
                md="3"
              />
            </Form.Row>
            <Form.Row>
              <Controller
                as={Input}
                control={control}
                name="state"
                label="Estado"
                md="3"
              />
              <Controller
                as={Input}
                control={control}
                name="number"
                label="Número"
                md="3"
              />
              <Controller
                as={Input}
                control={control}
                name="complement"
                label="Complemento"
                md="6"
              />
            </Form.Row>

            <hr />
            <Form.Row>
              <Controller
                as={Select}
                control={control}
                name="have_special_needs"
                label="PNE - (Portador de necessidades especiais)"
                options={have_special_needs}
                md="6"
              />
              <Controller
                as={Input}
                control={control}
                name="email"
                label="Email"
                md="6"
              />
            </Form.Row>
          </Card.Body>
          <Card.Footer>
            <Form.Row>
              <Button
                className="mr-3"
                disabled={loading}
                type="submit"
                variant="success"
              >
                {loading ? "Carregando..." : "Salvar"}
              </Button>
              <Button onClick={() => history.goBack()} variant="danger">
                Cancelar
              </Button>
            </Form.Row>
          </Card.Footer>
        </Form>
      </Card>
    </Container>
  );
};

export default EstagiarioForm;
