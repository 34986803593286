import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import Modal from "react-bootstrap/Modal";

import ComplexTable from "../../../components/ComplexTable";
import api from "src/services/axios";

import { useLocation } from "react-router-dom";
import { format, parseISO } from "date-fns";
import ExportJsonExcel from "js-export-excel";

import { useForm, Controller } from "react-hook-form";

import Select from "./../../../components/Select";
import InputDate from "./../../../components/InputDate";
import Swal from "sweetalert2";
import { handleGenerateReportAttendanceEachTrainne } from "src/screens/Relatorios/templates/report";
import { RiFileExcel2Fill } from "react-icons/ri";
import { FaFilePdf } from "react-icons/fa";
import { Col, Row } from "react-bootstrap";

interface ObservationData {
  id: string;
  type: string;
  date: string;
  cpf: string;
}

const AttendaceList: React.FC = () => {
  let data = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const [openedModal, setOpenedModal] = useState<boolean>(false);

  const [attendaces, setAttendaces] = useState<ObservationData[]>([]);

  const { handleSubmit, control, reset } = useForm({
    reValidateMode: "onSubmit",
    defaultValues: {
      type: null,
      date: new Date(),
    },
  });

  useEffect(() => {
    api.get(`/trainees/attendance?cpf=${data.state.cpf}`).then((response) => {
      setAttendaces(
        response.data.map((item) => {
          return {
            id: item.id,
            cpf: item.cpf,
            date: format(parseISO(item.date.substring(0, 10)), "dd/MM/yyyy"),
            type: item.type,
          };
        })
      );
    });
  }, [data.state.cpf]);

  function beforeSaveCell(oldValue, newValue, row, column, done) {
    setTimeout(() => {
      if (oldValue !== newValue) {
        Swal.fire({
          title: `Confirma edição de ${oldValue} para ${newValue} ?`,
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: `Confirmo`,
          denyButtonText: `Cancelar`,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            api
              .patch(`/trainees/attendanceManual/${row.id}`, { type: newValue })
              .then(() => {
                done(true);
                Swal.fire("Editado com sucesso!", "", "success");
              })
              .catch((error) => {
                done(true);
                Swal.fire("Houve um erro ao tentar atualizar !", "", "error");
              });
          } else if (result.isDenied) {
            done(false);
          }
        });
      } else {
        done(true);
      }
    }, 0);
    return { async: true };
  }

  const handleClose = () => setOpenedModal(false);
  const handleShow = () => setOpenedModal(true);

  const onSubmit = (values) => {
    setLoading(true);
    api
      .post("/trainees/attendanceManual", {
        date: values.date,
        type: values.type.value,
        cpf: data.state.cpf,
      })
      .then((response) => {
        const newData = {
          id: response.data.id,
          cpf: response.data.cpf,
          date: format(
            parseISO(response.data.date.substring(0, 10)),
            "dd/MM/yyyy"
          ),
          type: response.data.type,
        };
        setAttendaces([...attendaces, ...[newData]]);
        reset();
        handleClose();
      });
  };

  const handleGenerateExcelFile = () => {
    const parseContracts = attendaces.map((item) => {
      return {
        cpf: item.cpf,
        date: item.date,
        type: item.type,
        name: data.state.name,
      };
    });

    let option = {
      fileName: `${Date.now()}-presencas`,
      datas: [
        {
          sheetData: parseContracts,
          sheetName: "sheet",
          sheetFilter: ["name", "date", "type", "cpf"],
          sheetHeader: ["Nome", "Data", "Tipo", "CPF"],
        },
      ],
    };

    let toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  };
  return (
    <Container fluid>
      <Modal show={openedModal} onHide={handleClose}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>Adicionar registro</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Controller
              as={Select}
              control={control}
              name="type"
              label="Tipo"
              options={[
                {
                  label: "FALTA",
                  value: "FALTA",
                },
                {
                  label: "PRESENÇA",
                  value: "PRESENÇA",
                },
                {
                  label: "FALTA ABONADA",
                  value: "FALTA ABONADA",
                },
                {
                  label: "FÉRIAS",
                  value: "FÉRIAS",
                },
                {
                  label: "PRESENÇA AMBIENTAÇÃO",
                  value: "PRESENÇA AMBIENTAÇÃO",
                },
                {
                  label: "FALTA AMBIENTAÇÃO",
                  value: "FALTA AMBIENTAÇÃO",
                },
                {
                  label: "RECUPERAÇÃO",
                  value: "RECUPERAÇÃO",
                },
              ]}
              md="12"
              required
            />
            <Controller
              as={InputDate}
              control={control}
              name="date"
              label="Data"
              md="12"
              required
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleClose}>
              Cancelar
            </Button>
            <Button type="submit" variant="success">
              {loading ? "Carregando..." : "Salvar"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Card style={{ boxShadow: "var(--card-bs)" }}>
        <Card.Header
          className="d-flex justify-content-between align-items-center color-secondary"
          as="h5"
        >
          Presenças de: {data.state.name}
          <div>
            <Button onClick={handleShow} className="mr-3" variant="success">
              <i className="bi bi-plus-lg mr-2"></i>
              Nova presença
            </Button>
            <Button
              onClick={() =>
                handleGenerateReportAttendanceEachTrainne(
                  data.state.name,
                  attendaces
                )
              }
              className="mr-3"
              variant="primary"
            >
              <FaFilePdf className="mr-2" size={20} />
              Gerar PDF
            </Button>
            <Button onClick={handleGenerateExcelFile} variant="secondary">
              <RiFileExcel2Fill className="mr-2" />
              Gerar Excel
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col className="mb-4" sm="4">
              <Card
                style={{
                  boxShadow: "0 4px 1px var(--green-regular) !important",
                  background: "var(--bg-secondary)",
                  borderColor: "var(--green-regular)",
                  color: "var(--green-regular)",
                }}
                className="mb-4"
              >
                <Card.Body>
                  <Card.Title>Totais de presenças:</Card.Title>
                  <Card.Subtitle
                    className="mb-2"
                    style={{ fontSize: "36px", fontWeight: "bold" }}
                  >
                    {
                      attendaces.filter((item) => item.type === "PRESENÇA")
                        .length
                    }
                  </Card.Subtitle>
                </Card.Body>
              </Card>
            </Col>
            <Col className="mb-4" sm="4">
              <Card
                style={{
                  boxShadow: "0 4px 1px var(--red-regular) !important",
                  background: "var(--bg-secondary)",
                  borderColor: "var(--red-regular)",
                  color: "var(--red-regular)",
                }}
                className="mb-4"
              >
                <Card.Body>
                  <Card.Title>Totais de Faltas</Card.Title>
                  <Card.Subtitle
                    className="mb-2"
                    style={{ fontSize: "36px", fontWeight: "bold" }}
                  >
                    {attendaces.filter((item) => item.type === "FALTA").length}
                  </Card.Subtitle>
                </Card.Body>
              </Card>
            </Col>
            <Col className="mb-4" sm="4">
              <Card
                style={{
                  boxShadow: "0 4px 1px var(--slate-400) !important",
                  background: "var(--bg-secondary)",
                  borderColor: "var(--slate-400)",
                  color: "var(--slate-400)",
                }}
                className="mb-4"
              >
                <Card.Body>
                  <Card.Title>Recuperação</Card.Title>
                  <Card.Subtitle
                    className="mb-2"
                    style={{ fontSize: "36px", fontWeight: "bold" }}
                  >
                    {
                      attendaces.filter((item) => item.type === "RECUPERAÇÃO")
                        .length
                    }
                  </Card.Subtitle>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="mb-4" sm="12">
              <Card
                style={{
                  boxShadow: "0 4px 1px var(--secondary) !important",
                  background: "var(--bg-secondary)",
                  borderColor: "var(--secondary)",
                  color: "var(--secondary)",
                }}
                className="mb-4"
              >
                <Card.Body>
                  <Card.Title>Inicio do contrato</Card.Title>
                  <Card.Subtitle
                    className="mb-2"
                    style={{ fontSize: "36px", fontWeight: "bold" }}
                  >
                    {data.state?.contract?.start_validity
                      ? format(
                          parseISO(
                            data.state?.contract?.start_validity?.substring(
                              0,
                              10
                            )
                          ),
                          "dd/MM/yyyy"
                        )
                      : "Sem data de contrato no momento"}
                  </Card.Subtitle>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <ComplexTable
            data={attendaces}
            columns={[
              {
                dataField: "cpf",
                text: "CPF",
                sort: true,
                filter: false,
                editable: false,
              },
              {
                dataField: "date",
                text: "Data da presença/falta",
                sort: false,
                filter: false,
                editable: false,
              },
              {
                dataField: "type",
                text: "Presença/Falta",
                sort: false,
                filter: false,
                editor: {
                  type: Type.SELECT,
                  options: [
                    {
                      label: "FALTA",
                      value: "FALTA",
                    },
                    {
                      label: "PRESENÇA",
                      value: "PRESENÇA",
                    },
                    {
                      label: "FALTA ABONADA",
                      value: "FALTA ABONADA",
                    },
                    {
                      label: "FÉRIAS",
                      value: "FÉRIAS",
                    },
                    {
                      label: "PRESENÇA AMBIENTAÇÃO",
                      value: "PRESENÇA AMBIENTAÇÃO",
                    },
                    {
                      label: "FALTA AMBIENTAÇÃO",
                      value: "FALTA AMBIENTAÇÃO",
                    },
                    {
                      label: "RECUPERAÇÃO",
                      value: "RECUPERAÇÃO",
                    },
                  ],
                },
              },
            ]}
            cellEdit={cellEditFactory({
              mode: "click",
              blurToSave: true,
              beforeSaveCell,
            })}
          />
        </Card.Body>
      </Card>
    </Container>
  );
};

export default AttendaceList;
