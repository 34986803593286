import React, { useCallback, useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";

// Importações do react-bootrap
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useDropzone } from "react-dropzone";
import api from "src/services/axios";
import { toast } from "react-toastify";
import { Col, Row } from "react-bootstrap";
import { CiImport } from "react-icons/ci";

const DocumentsTrainnes: React.FC = () => {
  const location = useLocation();
  const params = useParams();
  const [currentFile, setCurrentFile] = useState(null);
  const [files, setFiles] = useState<any>([]);
  const [newFiles] = useState<any>([]);

  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
      newFiles.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files, newFiles]
  );

  const handleGetDocuments = useCallback(() => {
    api
      .get(`/trainees/documents?trainee_id=${params.id}`)
      .then((response) => {
        setFiles(
          response.data.map((file) => {
            return {
              id: file.id,
              file_id: file.files.id,
              type: file.files.content_type,
              url: file.files.url,
              name: file.files.name,
            };
          })
        );
      })
      .catch((error) => {
        toast.error("Houve um erro ao tentar recuperar os documentos");
      });
  }, [params.id]);

  useEffect(() => {
    if (params.id) {
      handleGetDocuments();
    }
  }, [handleGetDocuments, params, params.id]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: async (acceptedFiles) => {
      try {
        const formData = new FormData();
        formData.append("image", acceptedFiles[0]);

        await api.post(
          `/files?type_upload=trainee_document_upload&file_id=${currentFile}`,
          formData
        );

        toast.success("Atualizado com sucesso");
        handleGetDocuments();
      } catch {
        toast.error("Houve um erro ao tentar atualizar o documento");
      }
    },
  });

  const {
    getRootProps: getRootPropsNewFile,
    getInputProps: getInputPropsNewFile,
  } = useDropzone({
    accept: ["image/*", ".pdf"],
    onDrop: async (acceptedFiles) => {
      try {
        const formData = new FormData();
        formData.append("image", acceptedFiles[0]);

        await api.post(
          `/files?trainee_id=${params.id}&type_upload=trainee_document_create`,
          formData
        );

        toast.success("Adicionado com sucesso");

        handleGetDocuments();
      } catch {
        toast.error("Houve um erro ao tentar adicionar novo documento");
      }
    },
  });

  return (
    <>
      <Container fluid>
        <Card>
          <Card.Header
            as="h5"
            style={{
              color: "var(--secondary-color)",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px 20px",
            }}
          >
            Documentos do estágiario(a): {`${location?.state?.name}`}
          </Card.Header>
          <Card.Body>
            <Card
              className="mt-4 mb-4"
              style={{ border: "1px dashed #999", boxShadow: "none" }}
            >
              <Card.Body>
                <div
                  {...getRootPropsNewFile({ className: "dropzone" })}
                  className="d-flex justify-content-center align-items-center"
                >
                  <input {...getInputPropsNewFile()} />
                  <div
                    style={{ color: "var(--gray-400)" }}
                    className="interaction"
                  >
                    <CiImport size={25} className="mr-2" />
                    Clique aqui para adicionar um novo documento em imagem ou
                    PDF
                  </div>
                </div>
              </Card.Body>
            </Card>

            <hr />
            <Row>
              {files.map((file) => (
                <Col className="mb-4" md="6" key={file.id}>
                  <Card
                    className="mb-4 overflow-hidden bs-none"
                    onClick={() => {
                      setCurrentFile(file.file_id);
                    }}
                  >
                    <Card.Header className="text-center">
                      {file.name}
                    </Card.Header>
                    <Card.Body>
                      <div
                        {...getRootProps({ className: "dropzone" })}
                        className="d-flex justify-content-between  align-items-center"
                      >
                        <input {...getInputProps()} />
                        {file.type.includes("image") && (
                          <>
                            <img
                              src={file?.url}
                              style={{
                                display: "inline-flex",
                                border: "2px dashed var(--gray-400)",
                                borderRadius: "var(--br-md)",
                                marginBottom: 8,
                                marginRight: 8,
                                width: "100%",
                                height: 500,
                              }}
                              alt="Documento RG"
                            />
                          </>
                        )}
                        {file.type.includes("pdf") && (
                          <>
                            <img
                              src={
                                "https://upload.wikimedia.org/wikipedia/commons/thumb/3/38/Icon_pdf_file.svg/1200px-Icon_pdf_file.svg.png"
                              }
                              className="img-thumbnail"
                              style={{
                                display: "inline-flex",
                                border: "1px dashed var(--gray-400)",
                                borderRadius: "var(--br-md)",
                                marginBottom: 8,
                                marginRight: 8,
                                width: "100%",
                                height: 500,
                                padding: 4,
                                boxSizing: "border-box",
                              }}
                              alt="Documento pdf"
                            />
                          </>
                        )}
                      </div>
                    </Card.Body>
                    <Card.Footer>
                      <Button
                        className="button-primary"
                        onClick={() => window.open(file.url)}
                      >
                        Ver arquivo
                      </Button>
                    </Card.Footer>
                  </Card>
                </Col>
              ))}
            </Row>
          </Card.Body>
          <Card.Footer>
            <Form.Row>
              <Button variant="success" type="submit">
                Salvar
              </Button>
            </Form.Row>
          </Card.Footer>
        </Card>
      </Container>
    </>
  );
};

export default DocumentsTrainnes;
