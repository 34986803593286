import * as yup from 'yup';

const schema = yup.object().shape({

    start_validity: yup.date()
        .required("O campo 'Período vigência - Início' é obrigatório"),
    end_validity: yup.date()
        .required("O campo 'Período vigência - Fim' é obrigatório"),
   
    internship_scholarship_value: yup.number()
        .required("O campo 'Valor da bolsa' é obrigatório"),
    transportation_assistance_value: yup.number()
        .required("O campo 'Auxílio transporte' é obrigatório"),
    duration: yup.number()
        .required("O campo 'Jornada em horas' é obrigatório"),
    is_higher_education: yup
        .object()
        .nullable()
        .required("O campo 'É ensino superior?' é obrigatório"),
    course_id: yup
        .object()
        .nullable()
        .required("O campo 'Qual o curso?' é obrigatório"),
   
    work_activities: yup.string()
        .required("O campo 'Atividades' é obrigatório"),
    supervisor_education: yup
        .object()
        .nullable()
        .required("O campo 'Qual supervisor da educação?' é obrigatório"),
    supervisor_company: yup
        .object()
        .nullable()
        .required("O campo 'Qual o supervisor da empresa?' é obrigatório"),
});

export default schema;
